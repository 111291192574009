import '../global/styles/app.css';

import { type NextPage } from 'next';
import { type AppProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';

export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<
  P,
  IP
> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

interface NextAppProps extends AppProps {
  Component: NextPageWithLayout;
}
const NextApp = (props: NextAppProps) => {
  const {
    Component,
    pageProps: { session, ...pageProps },
  } = props;

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <SessionProvider>{getLayout(<Component {...pageProps} />)}</SessionProvider>
  );
};

export default NextApp;
